import { FC, ReactElement } from 'react'
import styled from 'styled-components'

type WaitingProps = {
    component?: string
    title: string
    height?: string
    width?: string
    backgroundColor?: string
    margin?: string
    padding?: string
    image?: string
}

const Waiting: FC<WaitingProps> = ({
    title,
    height = 'inherit',
    width = 'inherit',
    backgroundColor = '#f1f1f1',
    margin = 'inherit',
    padding = 'inherit',
    image = ''
}): ReactElement => {
    return (
        <WaitingContainer
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            margin={margin}
            padding={padding}
            image={image}
        >
            <Overlay padding={padding}>
                <Title>{title}</Title>
            </Overlay>
        </WaitingContainer>
    )
}

export default Waiting

const WaitingContainer = styled.div<{
    width: string
    height: string
    backgroundColor: string
    margin: string
    padding: string
    image: string
}>`
    background: #ffffffbf;
    background-image: url('${(props) => props.image}') !important;
    background-size: cover;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    margin: ${(props) => props.margin};
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 4px solid #00c7ff;
    box-sizing: border-box;
`

const Overlay = styled.div<{ padding: string }>`
    background: #ffffff2e;
    padding: ${(props) => props.padding};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
`
const Title = styled.div`
    background: #00c7ff;
    color: white;
    padding: 10px;
`
