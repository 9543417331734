import { JotaMaterialClose, tokens } from '@JOTAJornalismo/jota-design-system'
import Link from 'next/link'
import { FC, useState } from 'react'
import {
    CloseIconContainer,
    TextMobileCTAPRO,
    TextDesktopCTAPRO,
    ContainerLinkCTA,
    CTAContainer
} from './Header.styled'
import { AuthState } from 'src/api/types/auth'
import useScrollPosition from '@App/libs/hooks/useScroll'
import { LoadingLine } from '../loading/Loading.component'
const { colors } = tokens

type CTAJotaPropsType = {
    auth: AuthState
    showArticle?: boolean
    txtNormal?: string
    txtMobile?: string
    link?: string
}

const CTAJotaPro: FC<CTAJotaPropsType> = ({
    showArticle,
    txtNormal,
    txtMobile,
    link
}) => {
    const [show, setShow] = useState<boolean>(true)
    const scrollPosition = useScrollPosition()
    const SCROLL_LIMIT_NORMAL = 65

    return (
        <>
            {showArticle && show && link ? (
                <CTAContainer
                    scroll={scrollPosition > SCROLL_LIMIT_NORMAL}
                    data-testid='CTAPRO'
                >
                    <TextDesktopCTAPRO>
                        <ContainerLinkCTA>
                            <span>{txtNormal} </span>
                            <Link href={link}>Saiba mais</Link>
                        </ContainerLinkCTA>
                    </TextDesktopCTAPRO>

                    <TextMobileCTAPRO>
                        <ContainerLinkCTA>
                            <span>{txtMobile} </span>
                            <Link href={link}>Saiba mais</Link>
                        </ContainerLinkCTA>
                    </TextMobileCTAPRO>

                    <CloseIconContainer onClick={() => setShow(false)}>
                        <JotaMaterialClose fill={colors.white.jotaWhite} />
                    </CloseIconContainer>
                </CTAContainer>
            ) : (
                <LoadingLine />
            )}
        </>
    )
}

export default CTAJotaPro
