import {
    Accessibility,
    InputSearch,
    InputSearchContainer,
    LogoCompactContainer,
    LogoContainer,
    SearchCenter,
    SearchCompact,
    SearchCompactContainer
} from './Header.styled'
import {
    Input,
    JotaMaterialClose,
    JotaMaterialSearch,
    JotaMaterialSignLanguage as SignLanguageIcon,
    JotaMaterialTextIncrease as TextIncreaseIcon,
    tokens
} from '@JOTAJornalismo/jota-design-system'
import Image from 'next/legacy/image'
import { FC, useEffect } from 'react'
const { colors } = tokens
import { HEADER_CONSTANT } from '../../utils/constants'
import { useSearchStore } from '@App/zustand/store/store'
import { useRouter } from 'next/router'

type LogoI = {
    width?: number
    height?: number
}

export const LogoJotaHeader: FC<LogoI> = ({ width = 107, height = 40 }) => (
    <LogoContainer>
        <Image
            src={`${process.env.NEXT_PUBLIC_JU}/images/meta/jotalogo.svg`}
            alt='jota logo'
            width={width}
            height={height}
        />
    </LogoContainer>
)

export const AccessibilityComponent = () => (
    <Accessibility id='acessibility'>
        <TextIncreaseIcon fill='#73757F' type='decrease' />
        <SeparatorVertical />
        <TextIncreaseIcon fill={HEADER_CONSTANT.icons.colors} />
        <SignLanguageIcon fill={HEADER_CONSTANT.icons.colors} />
    </Accessibility>
)

export const SeparatorVertical = () => (
    <svg
        width='1'
        height='13'
        viewBox='0 0 1 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <line
            x1='0.5'
            y1='0.00781252'
            x2='0.499999'
            y2='12.0078'
            stroke='#9899A2'
            strokeOpacity='0.4'
        />
    </svg>
)

type JotaLogoMini = {
    color?: string
    isOnScroll?: boolean
}

export const LogoJ: FC<JotaLogoMini> = ({ color = '#F05841' }) => (
    <LogoCompactContainer onClick={() => (window.location.href = '/')}>
        <svg
            width='13'
            height='33'
            viewBox='0 0 13 33'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.1058 0.615723V20.6742C12.1058 22.3639 11.9816 23.7358 11.7363 
24.7958C11.4894 25.8573 11.0446 26.8685 10.4001 27.8368C9.49409 
29.2176 8.26288 30.3249 6.70503 31.1543C5.14717 31.9821 
3.47699 32.3961 1.69742 32.3961C1.63535 32.3961 1.37373 
32.3798 0.914062 32.3488V31.7279C1.14611 31.7441 1.40477 
31.7515 1.69742 31.7515C3.47699 31.7515 5.13978 31.3139 6.68138 30.4402C8.22297 
29.5635 9.40097 28.3986 10.2139 26.938C10.6898 26.079 11.0209 25.1787 11.2042 24.2326C11.3875 23.2879 
11.4821 22.1022 11.4821 20.6756V0.615723H12.1058ZM8.32939 0.615723H10.7016V20.6742C10.7016 22.2087 
10.5938 23.4476 10.378 24.3922C10.1622 25.3384 9.78083 26.2224 9.22804 27.0518C8.44468 28.267 7.38049 
29.2206 6.03695 29.9198C4.69341 30.6191 3.25676 30.9665 1.72255 30.9665C1.61318 30.9665 
1.50971 30.9621 1.41068 30.9547C1.31166 30.9488 1.14759 30.9443 0.917019 30.9443V28.7564H1.2614C2.16892 
28.7564 3.06018 28.6026 3.93222 28.2951C4.8087 27.9891 5.52851 27.5899 6.09607 27.0976C6.89422 26.4235 
7.4677 25.6163 7.81208 24.6805C8.15646 23.7462 8.33087 22.5709 8.33087 21.1576V20.6742V0.615723H8.32939Z'
                fill={color}
            />
        </svg>
    </LogoCompactContainer>
)

/**
 * Jota Search Compact - icon
 * @param {'open' | 'closed'} type
 * @return {ReactElement}
 */
export const JotaSearchCompactIcon: FC<{
    type?: 'open' | 'closed'
    onClick?: () => void
    onlyMobile?: boolean
}> = ({ type = 'closed', onClick, onlyMobile = false }) => {
    if (type === 'closed') {
        return (
            <SearchCompactContainer onClick={onClick} onlyMobile={onlyMobile}>
                <JotaMaterialSearch fill={colors.gray.jotaGray} />
            </SearchCompactContainer>
        )
    } else {
        return (
            <SearchCompactContainer onClick={onClick} onlyMobile={onlyMobile}>
                <JotaMaterialClose fill={colors.gray.jotaGray} />
            </SearchCompactContainer>
        )
    }
}

/**
 * Jota Search - compact
 * @return {ReactElement}
 */
export const JotaSearchCompact = () => {
    const { inputValue, setInputValue } = useSearchStore()
    const encodedInputValue = encodeURIComponent(inputValue)

    return (
        <SearchCompact>
            <SearchCenter>
                <Input
                    RightIcon='jotaMaterialSearch'
                    fillIcone='jotaGrayLight'
                    placeholder={HEADER_CONSTANT.search.input}
                    value={inputValue}
                    onChange={(e: any) => setInputValue(e.target.value)}
                    onKeyDown={(e: any) => {
                        if (
                            e.target.value &&
                            e.which === 13 &&
                            e?.target.value.length >= 2 &&
                            e?.target.value.length <= 200
                        ) {
                            window.location.href = `/busca/${encodedInputValue}`
                        }
                    }}
                    showClearText
                    onRightIconClick={() => {
                        if (
                            inputValue &&
                            inputValue.length >= 2 &&
                            inputValue.length <= 200
                        ) {
                            window.location.href = `/busca/${encodedInputValue}`
                        }
                    }}
                />
            </SearchCenter>
        </SearchCompact>
    )
}

/**
 * Input Search Component
 * @return {ReactElement}
 */
export const InputSearchComponent: FC = () => {
    const { inputValue, setInputValue } = useSearchStore()
    const encodedInputValue = encodeURIComponent(inputValue)
    const router = useRouter()

    useEffect(() => {
        if (router.pathname == '/') {
            setInputValue('')
        }

        const routerParams = (router?.query?.term as string)?.split('&')
        if (routerParams) setInputValue(routerParams[0])
    }, [router.pathname, router?.query?.term, setInputValue])

    return (
        <InputSearchContainer>
            <InputSearch>
                <Input
                    RightIcon='jotaMaterialSearch'
                    fillIcone='jotaGrayLight'
                    placeholder={HEADER_CONSTANT.search.input}
                    value={router.pathname !== '/' ? inputValue : ''}
                    onChange={(e: any) => setInputValue(e.target.value)}
                    onKeyDown={(e: any) => {
                        if (
                            e.target.value &&
                            e.which === 13 &&
                            e?.target.value.length >= 2 &&
                            e?.target.value.length <= 200
                        ) {
                            window.location.href = `/busca/${encodedInputValue}`
                        }
                    }}
                    onRightIconClick={() => {
                        if (
                            inputValue &&
                            inputValue.length >= 2 &&
                            inputValue.length <= 200
                        ) {
                            window.location.href = `/busca/${encodedInputValue}`
                        }
                    }}
                    showClearText
                />
            </InputSearch>
            <JotaSearchCompactIcon onlyMobile={true} />
        </InputSearchContainer>
    )
}

/**
 * Show or hide item by id - Pure JS
 * @param {string} id
 * @param {string} classValue
 * @param {number} width
 * @return {void}
 */
export const showHideItem = (
    id: string,
    classValue: string,
    width?: number
) => {
    const el = document.getElementById(`${classValue}_${id}`)
    if (el) {
        if (el.style.display === 'flex') {
            el.style.display = 'none'
        } else {
            el.style.display = id === '416126' ? 'flex' : 'block'
        }
        el.style.minWidth = width ? `${width}px` : 'inherit'
    }
}
