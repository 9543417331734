import { AccordionDS } from '../'
import {
    JotaOutrosLabelPRO as LabelProIcon,
    tokens
} from '@JOTAJornalismo/jota-design-system'
import {
    UserHeader,
    UserNameContainer,
    UserName,
    UserEmail,
    UserMenuDiv,
    UserContainerItem,
    AccordionContainer,
    SubMenuHamburguerContainer
} from './Header.styled'

const { colors } = tokens
import { FC } from 'react'

import { useRouter } from 'next/router'
import { AuthState } from '../../../api/types/auth'
import { UserMenuSampleAPI, menuSampleApiI } from '../../utils/fake_api'

interface UserMenuI {
    auth?: AuthState
    isHeader?: boolean
}

/**
 * Casa JOTA Component
 * @return {React.JSX.Element}
 */
const UserMenu: FC<UserMenuI> = ({
    auth,
    isHeader = false
}): React.JSX.Element => {
    const router = useRouter()

    const itemGetIcon = (item: menuSampleApiI) => {
        if (item.iconPos === 'left') {
            return (
                <AccordionDS
                    id={`${item.id}`}
                    key={item.id}
                    label={item.text}
                    type={'first'}
                    onClick={() => {
                        if (item.url) void router.push(item.url)
                        return {}
                    }}
                    LeftIcon={item.icon}
                    hasBorder={true}
                />
            )
        } else {
            return (
                <AccordionDS
                    id={`${item.id}`}
                    key={item.id}
                    label={item.text}
                    type={'first'}
                    onClick={() => {
                        if (item.url && !item.external) {
                            void router.push(item.url)
                        } else {
                            window.open(item.url)
                        }
                        return {}
                    }}
                    RightIcon={item.icon}
                    hasBorder={true}
                />
            )
        }
    }

    const makeMenu = () => {
        return UserMenuSampleAPI.map((item) => {
            if (item?.childs && item.childs.length > 0) {
                return (
                    <AccordionContainer key={item.id}>
                        <AccordionDS
                            id='teste'
                            key={item.id}
                            label={item.text}
                            type={'first'}
                            RightIcon={false}
                            hasBorder={true}
                        />
                        <SubMenuHamburguerContainer
                            className={`menu_${item.id}`}
                        >
                            {item.childs.map((child) => {
                                return (
                                    <AccordionDS
                                        key={child.id}
                                        id={`${child.id}`}
                                        label={child.text}
                                        type={'second'}
                                        LeftIcon={child.icon}
                                        onClick={() => {
                                            // click to route router.push
                                            return {}
                                        }}
                                    />
                                )
                            })}
                        </SubMenuHamburguerContainer>
                    </AccordionContainer>
                )
            } else {
                return item.icon ? (
                    itemGetIcon(item)
                ) : (
                    <AccordionDS
                        id={`${item.id}`}
                        key={item.id}
                        label={item.text}
                        type={'first'}
                        RightIcon={item.icon}
                        onClick={() => {
                            if (item.url) void router.push(item.url)
                            return {}
                        }}
                        hasBorder={true}
                    />
                )
            }
        })
    }

    return (
        <UserMenuDiv
            isHeader={isHeader}
            id='userMenuRoot'
            className='testeClass'
        >
            <UserHeader>
                <UserNameContainer>
                    <UserName>{auth?.user?.getName()}</UserName>
                    {auth?.isProUser && (
                        <LabelProIcon fill={colors.black.jotaBlack} />
                    )}
                </UserNameContainer>

                <UserEmail>{auth?.user?.getEmail()}</UserEmail>
            </UserHeader>

            <UserContainerItem id='menuUserXY'>
                {UserMenuSampleAPI.length > 0 && makeMenu()}
            </UserContainerItem>
        </UserMenuDiv>
    )
}

export default UserMenu
