import { BannerTop } from '@App/libs/interfaces/global.interface'
import { addDatalayer } from '@App/libs/utils/geral'
import Image from 'next/image'
import { FC } from 'react'
import styled from 'styled-components'

const BannerTopComponent: FC<BannerTop> = ({ image, url, scroll }) => {
    return (
        <ContainerBanner
            data-testid='banner-top'
            target='_blank'
            scroll={scroll}
            onClick={() => {
                addDatalayer({
                    event: 'clique_banner_top',
                    conteudo: 'banner_top',
                    link: url
                })
                window.open(url, '_blank')
            }}
        >
            {image && (
                <picture>
                    <source srcSet={image?.mobile} media='(max-width: 527px)' />
                    <source
                        srcSet={image?.tablet}
                        media='(min-width: 528px) and (max-width: 900px)'
                    />
                    <Image
                        src={image?.desktop}
                        alt='Banner Top JOTA INFO'
                        width={1420}
                        height={400}
                        layout='responsive'
                        loading='lazy'
                    />
                </picture>
            )}
        </ContainerBanner>
    )
}

export default BannerTopComponent

const ContainerBanner = styled.a<{ scroll?: boolean }>`
    ${(props) => props.scroll && `display: none;`}
    &:hover {
        cursor: pointer;
    }
`
