import Head from 'next/head'
import { FC } from 'react'

type metadataProps = {
    title?: string
    description?: string
    keywords?: string
    url: string
}

const HeadMetaComponent: FC<metadataProps> = ({ ...metadata }) => {
    const regexAMPNONBETA = /(.*?)(\?non_beta=1|&non_beta=1|\?amp|&amp).*$/
    const url =
        typeof window !== 'undefined' ? window.location.href : metadata.url
    const urlWithoutDate = url.replace(/(.*)-\d{8}$/, '$1')
    const cleanedUrl = urlWithoutDate.replace(regexAMPNONBETA, '$1')

    return (
        <Head>
            <link rel='canonical' href={cleanedUrl} />
        </Head>
    )
}

export default HeadMetaComponent
