/* eslint-disable new-cap */
import { FC } from 'react'
import styled from 'styled-components'
import useMediaQuery from './../../hooks/useMediaQuery'
import { BREAKPOINTS } from '../../utils/constants/layout'

type FakeProps = {
    desktopImg: string
    mobileImg: string
    title: string
}

const Fake: FC<FakeProps> = ({ desktopImg, mobileImg, title }) => {
    const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.SM}px)`)

    return (
        <FakeContainer>
            <TitleParent>
                <TitleContainer>{title}</TitleContainer>
            </TitleParent>
            <ImageContainer src={isMobile ? mobileImg : desktopImg} />
        </FakeContainer>
    )
}

export default Fake

const FakeContainer = styled.div``

const ImageContainer = styled.img`
    width: 100%;
`

const TitleContainer = styled.div`
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #00cdff;
    display: inline-block;
    width: inherit;
    padding: 6px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
`

const TitleParent = styled.div`
    position: relative;
`
