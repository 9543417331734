import React, { FC, useEffect, useRef, useState } from 'react'
import { AccordionDS, ButtonDS, LoadingLine } from '../'
import { AUTH_CONSTANT } from '../../utils/constants'
import { tokens } from '@JOTAJornalismo/jota-design-system'
const { colors } = tokens
import {
    NavBar,
    LinksContainer,
    List,
    MenuIconHover,
    ContainerNavBar,
    HeaderDesktopContainer,
    HeaderMobileContainer,
    HeaderMobileLeft,
    HeaderMobileRight,
    SubMenuHeader,
    SubMenuEditorial,
    ButtonContainer,
    SubMenuColumn,
    SubMenuColumnLeft
} from './Header.styled'
import {
    JotaSearchCompact,
    JotaSearchCompactIcon,
    LogoJotaHeader,
    showHideItem
} from './Header.utils.components'
import { UserLoggedBtn } from './UserBtnLogged'
import { useDispatch, useSelector } from 'react-redux'
import { sidebarToogle, searchCompactToogle } from '../../../api/ducks/ui'
import { AppDispatch, AuthState } from '../../../api/types/auth'
import { StoreType } from '../../../api/types/store'
import useScrollPosition from '../../hooks/useScroll'
import { MenuItem } from '../../interfaces'
import {
    ProPoderItems,
    ProPoderSaudeItems,
    ProTributosItems
} from '@App/libs/utils/fake_api'
import {
    SCROLL_LIMIT_NORMAL,
    SCROLL_POSITION_LIMIT
} from '@App/libs/utils/constants/global'

import { TYPE_OF_VERTICAL } from '@App/libs/utils/constants/pro'
import { normalizeUrl } from '@App/libs/utils/geral'

interface EditorialsComponentI {
    wpMenu?: MenuItem[]
    pageTributos: boolean
    auth?: AuthState
}

export const enum ProItems {
    Poder = 'ProPoderItems',
    Tributos = 'ProTributosItems',
    Saude = 'ProSaudeItems'
}

export const itemsMap = {
    Poder: ProPoderItems,
    Tributos: ProTributosItems,
    Saúde: ProPoderSaudeItems
}

const EditorialsComponent: FC<EditorialsComponentI> = ({
    wpMenu,
    pageTributos,
    auth
}): React.JSX.Element => {
    const dispatch = useDispatch<AppDispatch>()
    const { ui } = useSelector((state: StoreType) => state)
    const headerRef = useRef<HTMLDivElement>(null)
    const subMenuRef = useRef<HTMLDivElement>(null)
    const scrollPosition = useScrollPosition()
    const [wpMenuState, setWpMenuState] = useState<MenuItem[]>()
    const MENU_PARENT_NAME = 'editorialMenu'
    const MENU_CHILD_NAME = 'submenuHeaderEditorial'

    useEffect(() => {
        if (wpMenu) {
            const menuEmpty: MenuItem[] = Object.entries(wpMenu).map(
                (item) => item[1]
            )
            setWpMenuState(menuEmpty)
        }
    }, [wpMenu])

    const removeSubMenu = (id?: string) => {
        const childs = document.getElementsByClassName(
            MENU_CHILD_NAME
        ) as HTMLCollectionOf<HTMLElement>
        for (const child of childs) {
            const childId = `${MENU_PARENT_NAME}_${id}`
            if (childId !== child.id) {
                child.style.display = 'none'
            }
        }
    }

    /**
     * Menu Parent - Click event
     * @param {string} id
     * @return {void}
     */
    const clickParentItem = (id: string) => {
        // hide all elements
        removeSubMenu(id)

        const elParent = document.getElementById(`container_${id}`)
        showHideItem(id, MENU_PARENT_NAME, elParent?.clientWidth)
    }

    const getURLbyTitle = (title: string) => {
        if (title.split(' ').length > 3) {
            return title
                .split(' ')[0]
                .replace(':', '')
                .replace(/ /g, '-')
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
        }

        return title
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ /g, '-')
    }

    /**
     * Make SubMenu Contentby Vertical
     * @param {TYPE_OF_VERTICAL} title
     * @return {JSX.Element[] | null}
     */
    const makeSubMenuByVertical = (title: TYPE_OF_VERTICAL) => {
        const items = itemsMap[title]

        if (!items) return null

        return items.map((item) => {
            return (
                <AccordionDS
                    id={`${item.id}`}
                    key={item.id}
                    label={item.text}
                    type={'second'}
                    onClick={() => {
                        window.open(item.url, '_self')
                        return {}
                    }}
                    RightIcon={item.rightIcon}
                    LeftIcon={item.leftIcon}
                />
            )
        })
    }

    /**
     * Get Editorials
     * @param {MenuItem[]} wpMenu
     * @param {React.RefObject<HTMLDivElement>} subMenuRef
     * @return {string[]} list editorials
     */
    const getEditorials = (wpMenu: MenuItem[]) => {
        return (
            <List id='subMenuItens' onMouseLeave={() => removeSubMenu()}>
                {wpMenu?.map((menuChild) => {
                    if (menuChild?.children) {
                        const prepareChild = Object.entries(
                            menuChild?.children
                        ).map((menu) => menu[1])

                        if (
                            prepareChild.length > 0 ||
                            menuChild.title === 'Tributos' ||
                            menuChild.title === 'Saúde'
                        ) {
                            return (
                                <SubMenuEditorial
                                    key={menuChild.ID}
                                    className='menuParent'
                                    id={`container_${menuChild.ID}`}
                                >
                                    <button
                                        onMouseEnter={() => {
                                            clickParentItem(
                                                String(menuChild.ID)
                                            )
                                        }}
                                        onClick={() => {
                                            clickParentItem(
                                                String(menuChild.ID)
                                            )
                                            window.open(
                                                `/${getURLbyTitle(
                                                    menuChild.title
                                                )}`,
                                                '_self'
                                            )
                                            return {}
                                        }}
                                        role='menuitem'
                                        tabIndex={0}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            padding: 0,
                                            cursor: 'pointer',
                                            textAlign: 'left'
                                        }}
                                    >
                                        <AccordionDS
                                            key={menuChild.ID}
                                            id={`container_${menuChild.ID}`}
                                            label={menuChild.title}
                                            type='first'
                                            RightIcon={true}
                                            onClick={() => {
                                                clickParentItem(
                                                    String(menuChild.ID)
                                                )
                                                window.open(
                                                    `/${getURLbyTitle(
                                                        menuChild.title
                                                    )}`,
                                                    '_self'
                                                )
                                                return {}
                                            }}
                                            whiteSpace='nowrap'
                                        />
                                    </button>
                                    <SubMenuHeader
                                        id={`${MENU_PARENT_NAME}_${menuChild.ID}`}
                                        className={MENU_CHILD_NAME}
                                    >
                                        <SubMenuColumnLeft>
                                            {(menuChild.title === 'Poder' ||
                                                menuChild.title ===
                                                    'Tributos' ||
                                                menuChild.title ===
                                                    'Saúde') && (
                                                <AccordionDS
                                                    id={'exclusivo'}
                                                    key={'exclusivo'}
                                                    label={'exclusivo'}
                                                    type={'first'}
                                                    isSection
                                                    isPro
                                                />
                                            )}
                                            {makeSubMenuByVertical(
                                                menuChild.title as TYPE_OF_VERTICAL
                                            )}
                                        </SubMenuColumnLeft>
                                        <SubMenuColumn>
                                            {menuChild.title === 'Poder' && (
                                                <AccordionDS
                                                    id={'editorias-poder'}
                                                    key={'editorias-poder'}
                                                    label={'editorias'}
                                                    type={'first'}
                                                    isSection
                                                />
                                            )}
                                            {prepareChild.map((subitem) => {
                                                return (
                                                    <AccordionDS
                                                        type='second'
                                                        id={subitem.ID + ''}
                                                        key={subitem.ID}
                                                        label={subitem.title}
                                                        onClick={() => {
                                                            window.open(
                                                                `${normalizeUrl(subitem.url)}`,
                                                                '_self'
                                                            )
                                                            return {}
                                                        }}
                                                    />
                                                )
                                            })}
                                            <ButtonContainer
                                                style={{
                                                    borderTop: `1px solid ${colors.transparent.jotaTGrayLight40}`,
                                                    marginTop: 'auto'
                                                }}
                                            >
                                                <ButtonDS
                                                    label='Todos os conteúdos'
                                                    type='filled'
                                                    size='small'
                                                    color='white'
                                                    LastIcon='jotaMaterialArrow'
                                                    insideType='right'
                                                    fillIcone='jotaBlack'
                                                    onClick={() => {
                                                        clickParentItem(
                                                            String(menuChild.ID)
                                                        )
                                                        window.open(
                                                            `/${getURLbyTitle(
                                                                menuChild.title
                                                            )}`,
                                                            '_self'
                                                        )

                                                        return {}
                                                    }}
                                                />
                                            </ButtonContainer>
                                        </SubMenuColumn>
                                    </SubMenuHeader>
                                </SubMenuEditorial>
                            )
                        } else {
                            return (
                                <AccordionDS
                                    key={menuChild.ID}
                                    label={menuChild.title}
                                    type='first'
                                    id='accordion'
                                    onClick={() => {
                                        if (
                                            menuChild.title !==
                                                'Estúdio JOTA' &&
                                            menuChild.title !== 'Newsletters'
                                        ) {
                                            window.open(
                                                `/${getURLbyTitle(
                                                    menuChild.title.replace(
                                                        /[0-9]/g,
                                                        '! '
                                                    )
                                                )}`,
                                                '_self'
                                            )
                                        } else if (
                                            menuChild.title === 'Estúdio JOTA'
                                        ) {
                                            window.open(
                                                'https://conteudo.jota.info/estudio-jota?utm_source=rd_station&utm_medium=site&' +
                                                    'utm_campaign=banner_header_topo_home_estudio_jota&utm_id=header_estudio_jota&utm_term=lp_estudio_jota&utm_term=header_topo_home_estudio_jota'
                                            )
                                        } else if (
                                            menuChild.title === 'Newsletters'
                                        ) {
                                            window.open(
                                                `${process.env.NEXT_PUBLIC_JOTAINFO}/newsletter-jota`
                                            )
                                        }
                                        return {}
                                    }}
                                    whiteSpace='nowrap'
                                />
                            )
                        }
                    }
                })}
            </List>
        )
    }

    return (
        <>
            {wpMenuState ? (
                <ContainerNavBar ref={headerRef}>
                    <HeaderDesktopContainer>
                        <NavBar scroll={scrollPosition > SCROLL_LIMIT_NORMAL}>
                            <LinksContainer ref={subMenuRef}>
                                {wpMenuState &&
                                    wpMenuState.length > 0 &&
                                    scrollPosition <= SCROLL_LIMIT_NORMAL &&
                                    getEditorials(wpMenuState)}
                            </LinksContainer>
                        </NavBar>
                    </HeaderDesktopContainer>
                    <HeaderMobileContainer
                        logged={
                            auth?.authState ===
                            AUTH_CONSTANT.AUTH_STATE.USER_LOGGED
                        }
                    >
                        <HeaderMobileLeft>
                            <MenuIconHover
                                fill='#3C3F4C'
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                    void dispatch(sidebarToogle(true))
                                }}
                            />
                            <a href='/'>
                                <LogoJotaHeader />
                            </a>

                            <JotaSearchCompactIcon
                                type={
                                    ui.search.compactUI.show ? 'open' : 'closed'
                                }
                                onClick={() =>
                                    void dispatch(searchCompactToogle())
                                }
                            />
                        </HeaderMobileLeft>
                        <HeaderMobileRight>
                            {!pageTributos && (
                                <UserLoggedBtn
                                    showUserMenu={false}
                                    isHeader={false}
                                />
                            )}
                        </HeaderMobileRight>
                    </HeaderMobileContainer>
                    {ui.search.compactUI.show &&
                        scrollPosition > SCROLL_POSITION_LIMIT && (
                            <JotaSearchCompact />
                        )}
                    {ui.search.compactUI.show &&
                        scrollPosition < SCROLL_POSITION_LIMIT && (
                            <JotaSearchCompact />
                        )}
                </ContainerNavBar>
            ) : (
                <LoadingLine />
            )}
        </>
    )
}

export default EditorialsComponent
