import router, { useRouter } from 'next/router'
import { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    Buttons,
    LoggedMenu,
    UserMenuContainerEditorial
} from './Header.styled'
// import { SeparatorVertical } from './Header.utils.components'
import UserMenu from './UserMenu.component'
import { userMenuToogle } from '../../../api/ducks/ui'
import { AppDispatch } from '../../../api/types/auth'
import { StoreType } from '../../../api/types/store'
import {
    AUTH_CONSTANT,
    HEADER_CONSTANT,
    ROUTE_CONSTANT
} from '../../utils/constants'
import { Tag } from '@JOTAJornalismo/jota-design-system'

import {
    INSIDE_JU,
    JU_LOGINREDIRECT,
    NOTICIA_ROUTE,
    PRO_ROUTE
} from '@App/libs/utils/constants/auth'

/**
 * User Logged Button
 * Description: Login for user menu (logged) or links to login page
 * @param {User} user
 * @param {boolean} showUserMenu
 * @return {ReactElement}
 */
export const UserLoggedBtn: FC<{
    showUserMenu: boolean
    isHeader?: boolean
    signupLink?: string
}> = ({ isHeader = false }) => {
    const { ui, auth } = useSelector((state: StoreType) => state)
    const dispatch = useDispatch<AppDispatch>()
    const { asPath } = useRouter()

    if (auth?.authState === AUTH_CONSTANT.AUTH_STATE.USER_LOGGED) {
        return (
            <LoggedMenu isHeader={isHeader}>
                {auth?.user && (
                    <div data-cy='user-login'>
                        <Tag
                            RightIcon={'jotaMaterialArrow'}
                            fillIcone='jotaGray'
                            color='jotaBlack'
                            insideType={ui?.userMenu?.isOpen ? 'up' : 'down'}
                            label={auth.user.getFirstName()}
                            onClick={() => {
                                void dispatch(userMenuToogle())
                            }}
                            size='large'
                        />
                    </div>
                )}
                <UserMenuContainerEditorial showUser={ui.userMenu.isOpen}>
                    <UserMenu {...{ auth }} isHeader={isHeader} />
                </UserMenuContainerEditorial>
            </LoggedMenu>
        )
    } else {
        return (
            <Buttons isHeader={isHeader}>
                <Tag
                    label={HEADER_CONSTANT.btnLogin.text}
                    size='large'
                    color='jotaOrange'
                    type='colored'
                    onClick={() => {
                        if (
                            asPath.includes(NOTICIA_ROUTE) ||
                            asPath.includes(PRO_ROUTE)
                        ) {
                            localStorage.setItem(JU_LOGINREDIRECT, asPath)
                            localStorage.setItem(INSIDE_JU, 'true')
                        }
                        void router.push(ROUTE_CONSTANT.ROUTE.LOGIN)
                    }}
                />
            </Buttons>
        )
    }
}
